import React from 'react'

class Copyright extends React.Component {
  render () {
    return (
      <div>
        <div className='copyright py-4 text-center text-white'>
          <div className='container'><small>Copyright © 2023</small></div>
        </div>
      </div>

    )
  }
}

export default Copyright
